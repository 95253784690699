<template>
  <div id="app">
    <HeaderPrimary/>
    <router-view/>
    <FooterPrimary/>
  </div>
</template>

<script>

import HeaderPrimary from '@/components/HeaderPrimary.vue'
import FooterPrimary from '@/components/FooterPrimary.vue'

import ConfigJson from '@/assets/Config.json'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Camsdelta.com',
  data () {
    return {
      ConfigJson: ConfigJson
    }
  },
  components: {
    HeaderPrimary, FooterPrimary
  }
}
</script>

<style lang="scss">

.pageStatic {
  font-size: 16pt;
  color: #fff;
  padding-bottom: 32px;
  width: 80%;
  margin: 0 auto;
  border-radius: 2px;
  z-index: 20;
  position: relative;
}

.pageStatic p {
  padding-top: 8px;
  color: var(--main-font-color);
}

.pageStatic ul {
  padding-top: 1em;
  margin-left: 4.2em;
  margin-right: 4.2em;
}

.pageStatic ul li a {
  line-height: 1.6em;
  color: var(--main-accent-color);
}

.pageStatic img {
  max-height: 512px;
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  margin: 0 auto;
}

@media (max-width: 720px) {
  .pageStatic img {
    max-height: auto;
  }
  .pageStatic {
    width: 80%;
  }
}

:root {
    --main-bg-color: #12171C;
    --main-accent-color: #CF4900;
    --model-font-color: #FF6229;
    /* #ffdc11 */
    --main-font-color: #8C8F92;
    --main-active-color: #1A2026;
    /* #1a2026 */
}

* {
    color: inherit;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    //cursor: default;
    outline: inherit;
    text-decoration: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

//*:not([type='text']) {
//    -webkit-touch-callout: none;
//    -webkit-user-select: none;
//    -moz-user-select: none;
//    user-select: none;
//}

*,
:after,
:before {
    box-sizing: border-box
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;900&display=swap');

html {}

body {
    font-family: 'Inter', Helvetica Neue, Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    line-height: 1.42857143;
    background: var(--main-bg-color);
    color: var(--main-font-color);
    overflow: auto;
    overflow: overlay;
}

#app {
    /*height: 100vh;
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: stretch;*/
}

a {
    transition: all .3s ease;
    color: var(--main-accent-color);
}

a:hover {
    color: #fff !important;
}

a:active {
    color: var(--main-font-color);
}

@media (min-width: 700px) {

    html,
    body {
        height: 100%;
        width: 100%;
    }

    #app {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    }

}

/** {
    outline: solid 1px red;
}*/

body.Overflow_Disabled {
    /* overflow-x: hidden;
    overflow-y: hidden; */
}

.clearfix:after {
    content: "";
    clear: both;
    display: table
}

img {
    vertical-align: middle
}

a {
    color: var(--main-accent-color);
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #fff;
    /* padding: .32em; */
    /* margin: 0 0 .75em; */
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -.02em;
}

h1 {
    font-size: 32px;
}

h1,
h2 {
    font-style: normal;
    text-transform: uppercase;
}

h2 {
    font-size: 32px
}

h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    text-transform: none;
    font-style: normal;
}

p {
    margin-bottom: 1.2em;
    margin-top: 0.42em;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
}

.post__content {
    margin-left: 64px;
}

@media (max-width: 720px) {
    .post__content {
        margin-left: inherit;
    }
}

::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    z-index: 12;
    background: transparent;
    overflow: visible;
}

::-webkit-scrollbar-corner {
    background: #434953
}

::-webkit-scrollbar-thumb {
    width: 4px;
    min-height: 32px;
    z-index: 12;
    background-image: none;
    background-color: rgba(67, 73, 83, .32);
    background-clip: padding-box;
    border-width: 3px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    border-radius: 10px;
    -webkit-transition: background-color .32s ease-in-out 0s;
    transition: background-color .32s ease-in-out 0s
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(67, 73, 83, .64)
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-track {
    display: none
}

svg {
    pointer-events: none
}

.page-title {
    /* font-size: 20px; */
    margin-top: 16px;
}

.page-descr {
  margin-bottom: 1.2em;
  margin-top: 0.42em;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: var(--main-font-color);
  text-transform: inherit;
  font-style: inherit;
  letter-spacing: inherit;
}

.button {
    padding: 6px 16px 6px 16px;
    margin: 4px;
    border-radius: 4px;
    background-color: var(--main-accent-color);
    border: 1px solid var(--main-accent-color);
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    transition: all .3s ease;
    white-space: nowrap;
    transform: translateY(0px);
    opacity: 1;
    vertical-align: center;
}

.button svg {
    fill: #fff;
}

.button.button-big {
    padding: 16px 24px 16px 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
}

.button.button-gray {
    background-color: #2E3943;
    border-color: #2E3943;
    color: #fff;
}

.button.button-tag {
    border-radius: 8px;
    text-transform: uppercase;
    border: 2px solid rgba(0, 0, 0, 0);
    min-width: 32px;
}

.button.button-tag:not(.active):hover {
    border: 2px solid var(--main-accent-color);
}

.button.button-tag:hover {
    border-color: #2E3943;
}

.button.button-tag.active {
    border: 2px solid var(--main-accent-color);
}

.button.button-category {
  border-radius: 8px;
  text-transform: uppercase;
  min-width: 32px;
  border: 2px solid var(--main-accent-color);
  background-color: rgba(0, 0, 0, 0) !important;
}

.button.button-category:not(.active):hover {
  border: 2px solid rgba(0, 0, 0, 0);
  background-color: var(--main-accent-color) !important;
}

.button.button-category:hover {
  border-color: rgba(0, 0, 0, 0);
}

.button.button-tag.active {
  border: 2px solid var(--main-accent-color);
}

.button:hover {
    background-color: rgba(0, 0, 0, 0);
    color: var(--main-accent-color);
}

.button:hover svg {
    fill: var(--main-accent-color);
}

.button.button-gray:hover {
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
}

.button.button-gray:hover svg {
    fill: #fff;
}

.button:active {
    transition: all .1s ease;
    transform: translateY(1px);
    opacity: .64;
}

.button.active {
    background-color: var(--main-active-color);
    border: 2px solid var(--main-accent-color);
    cursor: default;
    opacity: .64;
    transform: translateY(0px) !important;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

footer .footer_about {
    background-color: var(--main-bg-color);
    padding-top: 6px;
    /* padding-left: 16px;
    padding-right: 16px; */

}

footer .footer_about h2 {
    margin: 6px auto;
    width: 90vw;

}

footer .footer_about>div {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    transition: all .3s ease;
    margin: 6px auto;
    width: 90vw;

}

footer .footer_description {
    margin: 5px;
    padding: 8px;
    flex: 1 1 420px;
}

footer .footer_description p {
    padding: 2px;
}

footer .footer_features {
    margin: 5px;
    padding: 8px;
    flex: 1 1 420px;
}

footer .footer_features ul {
    list-style: none;
    padding-left: 32px;
}

footer .footer_features ul li {
    line-height: 16pt;
    position: relative;
}

footer .footer_features ul li:before {
    content: '\2022';
    display: inline-block;
    position: absolute;
    left: -20px;
    font-size: 16pt;
    color: var(--main-font-color);
    /*
    display: inline-block;
    position: absolute;
    left: -22px;
    top: 6px;
    width: 16px;
    height: 18px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M211.8 339.8C200.9 350.7 183.1 350.7 172.2 339.8L108.2 275.8C97.27 264.9 97.27 247.1 108.2 236.2C119.1 225.3 136.9 225.3 147.8 236.2L192 280.4L300.2 172.2C311.1 161.3 328.9 161.3 339.8 172.2C350.7 183.1 350.7 200.9 339.8 211.8L211.8 339.8zM0 96C0 60.65 28.65 32 64 32H384C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96zM48 96V416C48 424.8 55.16 432 64 432H384C392.8 432 400 424.8 400 416V96C400 87.16 392.8 80 384 80H64C55.16 80 48 87.16 48 96z' fill='%23ff5c00'/%3E%3C/svg%3E");
    */

}

.icon_box {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    transition: all .3s ease;
}

.icon_box>.box {
    margin: 32px;
    padding: 6px;
    flex: 1 1 256px;
}

.icon_box>.box>.icon {
    margin-right: auto;
    margin-left: auto;
    width: 90px;
    height: 90px;
    border: 2px solid rgba(255, 255, 255, .05);
    border-radius: 50%;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.icon_box>.box>.icon img,
.icon_box>.box>.icon svg {
    width: 50px;
    height: 50px;
    vertical-align: middle;
    fill: var(--main-accent-color);

}

.icon_box>.box>.icon>a {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_box>.box>.icon>a,
.icon_box>.box>.icon>a * {
    cursor: pointer;

}

.icon_box>.box>.title {
    margin-right: auto;
    margin-left: auto;
    color: #fff;
    margin: 0 0 .75em;
    font-weight: 700;
    text-transform: uppercase;

}

.icon_box>.box>.descr {
    margin-right: auto;
    margin-left: auto;

}

.Menu_Country_Flag {
    width: 24px;
    margin-right: 6px;
}

ul.SlideLinks {
    margin: 6px auto;
    list-style: none;
}

ul.SlideLinks li {
    padding: 4px;
}

ul.SlideLinks li a {
    cursor: pointer;
    position: relative;
    font-size: 18px;
    line-height: 1.5em;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 32px;
    display: block;
    width: 100%;
    padding: 0 0 0 32px;
    color: #fff;
}

ul.SlideLinks li a:hover {
    color: #fff;
}

ul.SlideLinks li img {
    cursor: pointer;
}

ul.SlideLinks li a:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: transparent;
    transition: background-color .3s ease;
}

ul.SlideLinks li a:hover:before {
    background-color: var(--main-accent-color);
}

.side_roulette.roulette {
    width: 85%;
    padding: 2px 6px 2px 32px !important;
    display: flex;
    margin: 22px 22px;
    align-items: center;
    justify-content: flex-start;
    font-size: 20px;
    font-weight: 600;
    max-width: 175px;
    max-width: fit-content;
}

.roulette {
    background-color: var(--main-accent-color);
    border: 1px solid var(--main-accent-color);
    padding: 0 6px !important;
    padding-left: 32px !important;
    line-height: 24px !important;
    border-radius: 4px;
    position: relative;
    color: #fff;
    transition: background-image .3s ease;
}

.roulette:hover {
    background-color: transparent;
    color: var(--main-accent-color);
}

.roulette:before {
    opacity: 0 !important;
}

.roulette:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 32px;
    left: 0;
    transition: background-image .3s ease;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0.5C5.71442 0.5 4.45772 0.881218 3.3888 1.59545C2.31988 2.30968 1.48676 3.32484 0.994786 4.51256C0.502816 5.70028 0.374095 7.00721 0.624899 8.26809C0.875703 9.52896 1.49477 10.6872 2.40381 11.5962C3.31285 12.5052 4.47104 13.1243 5.73192 13.3751C6.99279 13.6259 8.29973 13.4972 9.48744 13.0052C10.6752 12.5132 11.6903 11.6801 12.4046 10.6112C13.1188 9.54229 13.5 8.28558 13.5 7C13.4967 5.27711 12.8108 3.62573 11.5925 2.40746C10.3743 1.18918 8.72289 0.503304 7 0.5ZM9.275 7.41875L6.275 9.41875C6.19226 9.46995 6.09729 9.49801 6 9.5C5.91754 9.49994 5.83623 9.48068 5.7625 9.44375C5.68257 9.40061 5.61589 9.3365 5.56965 9.25832C5.5234 9.18014 5.49932 9.09083 5.5 9V5C5.49932 4.90917 5.5234 4.81986 5.56965 4.74168C5.61589 4.6635 5.68257 4.59939 5.7625 4.55625C5.84276 4.51562 5.93221 4.49657 6.02206 4.50095C6.11191 4.50533 6.19908 4.533 6.275 4.58125L9.275 6.58125C9.34464 6.62669 9.40185 6.68877 9.44145 6.76189C9.48105 6.83501 9.50178 6.91685 9.50178 7C9.50178 7.08315 9.48105 7.16499 9.44145 7.23811C9.40185 7.31123 9.34464 7.37331 9.275 7.41875Z' fill='%23FFF'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
}

.roulette:hover:after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0.5C5.71442 0.5 4.45772 0.881218 3.3888 1.59545C2.31988 2.30968 1.48676 3.32484 0.994786 4.51256C0.502816 5.70028 0.374095 7.00721 0.624899 8.26809C0.875703 9.52896 1.49477 10.6872 2.40381 11.5962C3.31285 12.5052 4.47104 13.1243 5.73192 13.3751C6.99279 13.6259 8.29973 13.4972 9.48744 13.0052C10.6752 12.5132 11.6903 11.6801 12.4046 10.6112C13.1188 9.54229 13.5 8.28558 13.5 7C13.4967 5.27711 12.8108 3.62573 11.5925 2.40746C10.3743 1.18918 8.72289 0.503304 7 0.5ZM9.275 7.41875L6.275 9.41875C6.19226 9.46995 6.09729 9.49801 6 9.5C5.91754 9.49994 5.83623 9.48068 5.7625 9.44375C5.68257 9.40061 5.61589 9.3365 5.56965 9.25832C5.5234 9.18014 5.49932 9.09083 5.5 9V5C5.49932 4.90917 5.5234 4.81986 5.56965 4.74168C5.61589 4.6635 5.68257 4.59939 5.7625 4.55625C5.84276 4.51562 5.93221 4.49657 6.02206 4.50095C6.11191 4.50533 6.19908 4.533 6.275 4.58125L9.275 6.58125C9.34464 6.62669 9.40185 6.68877 9.44145 6.76189C9.48105 6.83501 9.50178 6.91685 9.50178 7C9.50178 7.08315 9.48105 7.16499 9.44145 7.23811C9.40185 7.31123 9.34464 7.37331 9.275 7.41875Z' fill='%23FF5C00'/%3E%3C/svg%3E%0A");
}

.OnlyMobile {
    display: none;
}

@media (max-width: 1080px) {
    .OnlyMobile {
        display: block !important;
    }
}

.dropdown {
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: 32px;
    width: 138px;
    background: #f2f2f2;
    border: 1px solid;
    margin-top: 2px;
    vertical-align: middle;
    border-radius: 8px;
}

.dropdown:before,
.dropdown:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 10px;
    width: 0;
    height: 0;
    border: 4px dashed;
    border-color: #888888 transparent;
    pointer-events: none;
}

.dropdown:before {
    border-bottom-style: solid;
    border-top: none;
}

.dropdown:after {
    margin-top: 7px;
    border-top-style: solid;
    border-bottom: none;
}

.dropdown select {
    position: relative;
    width: 130%;
    margin: 0;
    padding: 6px 8px 6px 10px;
    height: 28px;
    line-height: 14px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.05em;
    vertical-align: middle;
    text-transform: uppercase;
    color: #62717a;
    background: #f2f2f2;
    background: rgba(0, 0, 0, 0) !important;
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.dropdown select:focus {
    z-index: 3;
    width: 100%;
    color: #394349;
}

.dropdown select>option {
    margin: 3px;
    padding: 6px 8px;
    text-shadow: none;
    background: #f2f2f2;
    border-radius: 3px;
    cursor: pointer;
}

.dropdown-dark {
    background-color: #2E3943;
    border-color: #2E3943;
}

.dropdown-dark:before {
    border-bottom-color: #aaa;
}

.dropdown-dark:after {
    border-top-color: #aaa;
}

.dropdown-dark select {
    color: #fff;
    text-shadow: 0 1px black;
    background: #444;
}

.dropdown-dark select:focus {
    color: #ccc;
}

.dropdown-dark select>option {
    background: #444;
    text-shadow: 0 1px rgba(0, 0, 0, 0.4);
}

.label {
    font-weight: 700;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: .25em;
    max-width: stretch;
    white-space: normal;
    background-color: var(--main-accent-color);
    display: block;
    float: left;
    text-transform: uppercase;
    margin: 3px;
    color: #1e2024;
    padding: .3em .5em;
    font-size: 10px;
}

.label.dark {
    background-color: #2E3943;
    color: #fff;
    font-size: 16px;
    padding: 8px 12px;
    margin-left: 16px;
    border-radius: 6px;
    text-transform: uppercase;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.loading_skeleton {
    -webkit-animation: skeleton 1s ease-in-out forwards infinite;
    animation: skeleton 1s ease-in-out forwards infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    background-color: #ffffff08;
    background: linear-gradient(to right, #ffffff08 8%, #ffffff16 18%, #ffffff00 33%);
    background-size: 800px 104px;
}

@-webkit-keyframes skeleton {
    0% {
        opacity: 0.2;
        background-position: -400px 0;
        transform: translateY(6px) scale(0.98);
    }

    85%,
    100% {
        opacity: 1;
        background-position: 400px 0;
        transform: translateY(0px) scale(1);
    }
}

@keyframes skeleton {
    0% {
        opacity: 0.2;
        background-position: -400px 0;
        transform: translateY(6px) scale(0.98);
    }

    85%,
    100% {
        opacity: 1;
        background-position: 400px 0;
        transform: translateY(0px) scale(1);
    }
}

.paginator {
    display: flex;
    width: 100%;
    padding: 12px;
    justify-content: center;
}

.paginator_spacing {
    padding: 6px 16px 2px 16px;
    margin: 4px;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}

.Badge {
    background-color: #2E3943;
    padding: 4px 8px;
    margin-left: 16px;
    margin-top: 8px;
    border-radius: 6px;
    min-height: 38px;
    text-transform: uppercase;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.scroll_wrapper {
    position: absolute;
    display: flex;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    overflow-x: scroll;
    overflow-x: overlay;
    scrollbar-width: none;
    white-space: nowrap;
    border-radius: 8px;
    padding-bottom: 10px;
}

.scroll_wrapper::-webkit-scrollbar {
    display: none;
}

.scroller {
    position: absolute;
    z-index: 6;
    width: 100px;
    top: 0;
    bottom: 12px;
    flex-grow: 1 !important;
    border-radius: 8px;
    cursor: pointer;
    transition: all .150ms ease;
    opacity: 1;
}

@media (max-width: 512px) {
    .scroller {
        width: 0 !important;
        opacity: 0 !important;
    }
}

@media (max-width: 720px) {
  .label {
    font-size: 7px;
  }
}

.scroller.hidden {
    width: 0;
    opacity: 0;
}

.scroller:hover {
    filter: contrast(1.2);
}

.scroller.scroller_left {
    left: -2px;
    background: linear-gradient(90deg, var(--main-bg-color), rgba(255, 255, 255, 0.001));
}

.scroller.scroller_right {
    right: -2px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.001), var(--main-bg-color));
}

.header_primary .header_search,
.Slim_search .header_search,
.header_search {
    display: inline-block;
    text-align: left;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    transition: all .3s ease;
    height: 32px;
    margin: 0 auto;
}

.header_primary .header_search,
.Slim_search .header_search,
.header_search {
    border-radius: 32px;
    border: 1px solid #2E3943;
}

.header_primary .header_search input,
.Slim_search .header_search input,
.header_search input {
    /* max-width: 128px; */
    width: 500px;
    padding-left: 12px;
    min-width: 64px;
    background: transparent;
    line-height: 42px;
    cursor: initial;
    color: white;
    caret-color: var(--main-accent-color);
}

.header_primary .header_search a,
.Slim_search .header_search,
.header_search a {
    cursor: pointer;
    transition: all .3s ease;
}

.header_primary .header_search a *,
.Slim_search .header_search a *,
.header_search a * {
    cursor: pointer;
}

.header_primary .header_search a,
.Slim_search .header_search a,
.header_search a {
    padding: 0px 10px;
    border-radius: 0px 32px 32px 0px;
}

.header_primary .header_search a:hover,
.Slim_search .header_search a:hover,
.header_search a:hover {
    background-color: var(--main-active-color);
}

.header_primary .header_search a svg,
.Slim_search .header_search a svg,
.header_search a svg {
    fill: #fff;
    margin-top: 2px;
    transition: fill .3s ease;
}

.header_primary .header_search:hover a svg,
.Slim_search .header_search:hover a svg,
.header_search:hover a svg {
    fill: var(--main-accent-color);
}

hr {
    background: #2E3943;
    width: 100%;
    margin: 4px auto;
    height: 1px;
}

.OnlineCounter {
    margin-left: 32px;
    display: initial;
    position: relative;
    color: #fff;
}

.OnlineCounter:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    bottom: 4px;
    left: -12px;
    background-color: var(--main-accent-color);

}

.SubHeader {
    width: 100%;
    margin: 0 auto;
    padding: 4px 0;
}

@media (max-width: 1080px) {
    .SubHeader {
        padding: 4px 12px!important;
        display: none;
    }
    .card_header {
        padding: 16px 12px!important;
    }
}

@media (max-width: 720px) {
  .MapChartWrapper {
    display: none;
  }
}

.StsticPage {
    margin-top: 24px !important;
}

#map-svg,
.vue-world-map svg,
.MapChartWrapper svg {
    pointer-events: all !important
}

</style>
