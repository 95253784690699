<template>
  <Accordion popup="true" v-bind:infooter="infooter" v-bind:Icon="'/assets/flags/h24/'+ArrayGet(locales, lang).iso+'.jpg'" v-bind:title="ArrayGet(locales, lang).name">
    <ul class="langs">
      <li class="lang" v-for="(language, code) in locales" :key="code" v-bind:class="code === lang ? 'active' : ''">
        <a v-if="code === 'en'" :href="getUrl(code)" v-on:click="setDefaultLanguage">
          <img v-bind:alt="code" v-bind:src="'/assets/flags/h24/'+(language.iso != null ? language.iso : code)+'.jpg'" width="24" height="16"/>
          <span>{{ language.name }}</span>
        </a>
        <a v-else :href="getUrl(code)" v-on:click="setDefaultLanguage">
          <img v-bind:alt="code" v-bind:src="'/assets/flags/h24/'+(language.iso != null ? language.iso : code)+'.jpg'" width="24" height="16"/>
          <span>{{ language.name }}</span>
        </a>
      </li>
    </ul>
  </Accordion>
</template>

<script>

import Accordion from '@/components/Accordion.vue'
import locales from '../lang/translations'

export default {
  name: 'LangSelector',
  props: [
    'locale',
    'infooter'
  ],
  components: {
    Accordion
  },
  data () {
    return {
      active: false,
      locales: locales
    }
  },
  mounted: function () {
    this.$nextTick(function () {
    })
  },
  methods: {
    setDefaultLanguage () {
      this.$i18n.locale = 'en'
      document.querySelector('html').setAttribute('lang', 'en')
      localStorage.setItem('VueAppLanguage', 'en')
      this.$store.commit('clearMeta')
    },
    eventChangeLocale (e) {
      this.$store.commit('clearMeta')
    },
    getUrl (code) {
      const regexp = /^\/[a-z]{2}\//
      const regexpCurrentCode = '/^/' + code + '//'

      if (code === 'en') {
        return this.$route.path.replace(regexp, '/')
      }

      if (this.$i18n.locale === 'en' && code === this.$i18n.locale) {
        return this.$route.path
      }

      if (this.$route.path.match(regexpCurrentCode)) {
        return this.$route.path
      }

      if (this.$route.path.match(regexp)) {
        return this.$route.path.replace(regexp, `/${code}/`)
      }

      return `/${code}${this.$route.path}`
    },
    ArrayGet (array, key) {
      if (array !== undefined && array[key] !== undefined) {
        return array[key]
      } else {
        return ''
      }
    }
  },
  computed: {
    lang: {
      get () {
        return this.$i18n.locale
      }
    }
  }
}
</script>

<style scoped lang="scss">

.site_logo > .simple {
  margin-top: 10px;
}

ul.langs {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 120px;
    overflow: hidden;
}

li.lang>a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px;
    width: 100%;
    overflow: hidden;
}

li.lang.active span {
    color: var(--main-accent-color) !important;
}

li.lang:hover span {
    color: #fff;
}

li.lang>a>* {
    margin-left: 4px;
    margin-right: 4px;
    pointer-events: none;
}

li.lang span {
    flex: 70%;
    float: left;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

li.lang img {
    flex: 30%;
    max-width: 20px;
}

</style>
