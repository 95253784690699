import Vue from 'vue'
import Vuex from 'vuex'
import { i18n } from 'vue-lang-router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    online: '',
    meta: {},
    burgerHidden: false
  },
  getters: {
    getOnline: state => state.online,
    getMeta: state => state.meta,
    isBurgerHidden: state => state.burgerHidden
  },
  mutations: {
    clearMeta (state) {
      this.meta = {}
    },
    updateOnline (state, newOnline) {
      state.online = newOnline
    },
    BurgerHidden (state, isHidden) {
      state.burgerHidden = isHidden
    },
    SetMeta (state, meta) {
      SetDocumentMeta(meta)
    },
    UpdateMeta (state, params) {
      if (Object.keys(state.meta).length === 0) {
        FetchMeta(i18n.locale, function (json) {
          state.meta = json

          let meta = state.meta[params.key]
          if (meta !== undefined) {
            if (params.meta !== undefined && typeof params.meta === 'object') {
              meta = { ...meta, ...params.meta }
            }
            SetDocumentMeta(meta, params.tags)
          }
        })
      } else {
        let meta = state.meta[params.key]
        if (meta !== undefined) {
          if (params.meta !== undefined && typeof params.meta === 'object') {
            meta = { ...meta, ...params.meta }
          }
          SetDocumentMeta(meta, params.tags)
        }
      }
    },
    GetMetaCallback (state, callback) {
      if (Object.keys(state.meta).length === 0) {
        FetchMeta(i18n.locale, (json) => {
          state.meta = json
          callback(json)
        })
      } else {
        callback(state.meta)
      }
    }
  }
})

function FetchMeta (locale, callback) {
  const v = new Date().toLocaleDateString()
    .replaceAll('.', '')
    .replaceAll('/', '')

  fetch(`/assets/meta/${locale}.json?v=${v}`, {
    cache: 'force-cache'
  })
    .then(response => response.json())
    .then(json => {
      callback(json)
    })
    .catch(function (error) {
      console.log(error)
    })
}

function SetDocumentMeta (meta, tags) {
  if (meta.description != null && meta.description) {
    const descr = replaceAll(tags, meta.description)
    document.querySelector('meta[name="description"]').content = descr
    document.querySelector('meta[name="twitter:description"]').content = descr
    document.querySelector('meta[property="og:description"]').content = descr
  }

  if (meta.title != null && meta.title) {
    const title = replaceAll(tags, meta.title)
    document.querySelector('title').innerHTML = title
    document.querySelector('meta[name="twitter:title"]').content = title
    document.querySelector('meta[property="og:title"]').content = title
  }

  if (meta.image != null && meta.image) {
    document.querySelector('meta[name="twitter:image"]').content = meta.image
    document.querySelector('meta[property="og:image"]').content = meta.image
  }
}

export function replaceAll (tags, str) {
  for (const key in tags) {
    str = str.replaceAll('{{' + key + '}}', tags[key])
  }
  return str
}
