<template>
            <div class="" v-bind:class=" simple == 'true' || popup == 'true'  ? 'simple' : '' ">
                <div v-if="this.HasSubmenu == true || this.HasSubmenu == undefined || popup == 'true'" class="tab__header" v-bind:class=" popup == 'true' ? 'popup' : '' " @click.prevent="ClickEventHandler">
                  <img v-if="this.Icon != null" v-bind:alt="this.Icon" class="Accordeon_Icon" v-bind:src="this.Icon" width="24" height="16"/>
                  <strong>{{title}}</strong>
                  <span v-if="!active || (active && popup)">
                    <svg width="8" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.5 0.5L3 3L0.5 0.5" stroke="#FF5C00" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                  <span v-if="active && !popup">
                    <svg width="8" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.5 3L3 0.5L0.5 3" stroke="#FF5C00" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                </div>
                <a v-if="this.HasSubmenu != true && this.Link" v-bind:href="this.Link" class="tab__header">
                  <strong>{{title}}</strong>
                </a>
                <div class="tab__content" v-show="active" v-bind:class=" infooter == 'true' ? 'infooter' : '' "><slot /></div>
            </div>
</template>

<script>
export default {
  name: 'AccordionComponent',
  props: [
    'title',
    'simple',
    'popup',
    'Link',
    'Icon',
    'defaultActive',
    'infooter',
    'HasSubmenu'
  ],
  data () {
    return {
      active: false
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      if (this.defaultActive) this.active = true
    })
  },
  methods: {
    ClickEventHandler: function () {
      if (this.popup !== 'true') {
        this.active = !this.active
      }
    }
  }
}
</script>

<style scoped lang="scss">

.tab__header {
    padding: 10px;
    font-size: 14pt;
    font-weight: 700;
    border-radius: 6px;
    border: 1px solid #ffffff32;
    background-color: #ffffff24;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    position: relative;
}

.tab__header * {
    cursor: pointer;
}

.tab__header:hover {
    border: 1px solid #ffffff42;
    background-color: #ffffff16;
    color: var(--main-accent-color);
}

.simple {
    position: relative;
    /*  */
}

.simple strong {
    padding-left: 1em;
}

strong {
    padding-left: 6px;
}

.tab__content {
    margin-bottom: 8px;
    max-height: 256px;
    overflow: auto;
    overflow: overlay;
}

.tab__header:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 2px;
    height: 100%;
    background-color: transparent;
    transition: background-color .3s ease;
}

.simple .tab__header strong {
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
}

.tab__header:hover strong {
    transition: color .3s ease;
    color: var(--main-accent-color);
}

.Accordeon_Icon {}

.simple .tab__header {
    padding: 10px;
    font-size: 14pt;
    font-weight: 700;
    border-radius: 6px;
    border: 0;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    position: relative;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: -.02em;
    line-height: 1.2em;
    border-bottom: 1px solid rgba(255, 255, 255, .05);
}

.simple .tab__content {
    margin-bottom: 2px;
    padding: 6px 18px;

}

.simple .tab__header span {
    color: var(--main-accent-color);
    font-size: 10px;
    margin-right: 3vw;
    margin-left: 6px;
    padding-top: 3px;
}

.simple .tab__header img {
    max-width: 24px;
    -o-object-fit: contain;
    object-fit: contain;
}

.tab__header.popup {
    padding: 0;
    line-height: 16px;
    border: 0;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0;
}

.tab__header.popup strong {
    padding: 0;
    font-size: 12pt;
    font-weight: 400;
    border-radius: 6px;
    line-height: 18px;
    margin-left: 4px;
    text-transform: capitalize;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.simple .tab__header.popup span {
    font-size: 10px;
    margin-right: 0;
    padding-top: 0px;
}

.tab__header.popup:hover~.tab__content,
.tab__header.popup~.tab__content:hover,
.tab__header.popup:active~.tab__content,
.tab__header.popup~.tab__content:active {
    display: block !important;
}

.tab__header.popup~.tab__content {
    position: absolute;
    background-color: var(--main-active-color);
    min-height: 64px;
    border-radius: 6px;
    z-index: 999;
    border: 1px solid var(--main-bg-color);
    transition: all .3s ease;
    top: 18px;
    right: -16%;
    padding: 4px;
    max-height: 200px;
}

.tab__header.popup~.tab__content.infooter {
    bottom: 16px;
    top: unset;
}

</style>
