<template>
  <footer>
    <div class="footer">
      <div class="footer_about" v-if="this.$router.currentRoute.name === 'Home'">
        <div>
          <div class="footer_description">
            <h4> {{ ConfigJson.BottomTitle }} </h4>
            <p v-for="(Descr, index) in ConfigJson.BottomDescr" :key="index"> {{ Descr }} </p>
          </div>
        </div>
      </div>
      <div class="footer_navigate">
        <div class="site_logo">
          <localized-link v-bind:to="'/'" v-bind:title="ConfigJson.WebsiteName" class="header_logo">
            <img v-bind:alt="ConfigJson.WebsiteName" v-bind:src="ConfigJson.LogoImage" width="200" height="63"/>
          </localized-link>
          <LangSelector v-bind:locale="this.$i18n.locale" infooter="true"/>
        </div>
        <div class="site_info">
          <h4>{{ ConfigJson.TitleText }}</h4>
          <p>
            {{ $t('SiteInfo') }}
          </p>
          <p class="site_info_mobile">
            {{ $t('TitleText') }}
            {{ $t('TitleDescr') }}
          </p>
        </div>
        <div class="site_index">
          <ul>
            <li v-for="(SiteIndex, index) in ConfigJson.SiteIndex" :key="index" v-bind:style=" SiteIndex.Hidden === 'Hidden' ? 'display:none!important' : '' ">
              <localized-link v-bind:to="SiteIndex.Url" v-bind:title="SiteIndex.Tooltip" rel="nofollow"> {{ $t(SiteIndex.Title) }} </localized-link>
            </li>
          </ul>
        </div>
<!--        <div class="site_footer">-->
<!--          <ul>-->
<!--            <li v-for="(SiteFooter, index) in ConfigJson.SiteFooter" :key="index">-->
<!--              <localized-link v-bind:to="SiteFooter.Url" v-bind:title="SiteFooter.Tooltip">{{ $t(SiteFooter.Title) }}</localized-link>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
        <div class="rta18">
          <svg width="82" height="23" viewBox="0 0 82 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.15">
            <path d="M0 0H20.6665C23.2068 0 25.0043 0.461072 26.0592 1.38322C27.2001 2.39114 27.7706 4.16037 27.7706 6.69091C27.7706 8.66387 27.5338 10.1221 27.0602 11.0657C26.5866 12.0093 25.7578 12.717 24.5738 13.1888C25.8654 13.7464 26.7158 14.304 27.1248 14.8615C27.5553 15.4191 27.7706 16.4163 27.7706 17.8531V23H20.6665V18.8825C20.6665 18.2821 20.505 17.7781 20.1821 17.3706C19.7731 16.8345 19.1596 16.5664 18.3415 16.5664H7.10411V23H0V0ZM7.10411 5.95105V10.6154H18.503C19.278 10.6154 19.8269 10.4545 20.1498 10.1329C20.4943 9.78974 20.6665 9.17856 20.6665 8.2993C20.6665 7.42005 20.4943 6.80886 20.1498 6.46574C19.8054 6.12261 19.2564 5.95105 18.503 5.95105H7.10411Z" fill="white"/>
            <path d="M19.8463 5.95105V0H60.781V5.95105H42.5364V23H35.4323V5.95105H19.8463Z" fill="white"/>
            <path d="M58.3627 0.0321674H70.5688L82 23H73.7011L71.5053 19.172H57.4262L55.2304 23H46.9315L58.3627 0.0321674ZM65.0147 5.21119H63.9168L60.0741 13.221H68.8251L65.0147 5.21119Z" fill="white"/>
            </g>
          </svg>
          <div class="eighteen">18+</div>
        </div>
      </div>
      <div class="footer_links">
        <span> &#169; {{ ConfigJson.WebsiteName }} {{ new Date().getFullYear() }} </span>
      </div>
    </div>
  </footer>
</template>

<script>

import ConfigJson from '@/assets/Config.json'

import LangSelector from '@/components/LangSelector.vue'

export default {
  name: 'FooterPrimary',
  data () {
    return {
      ConfigJson: ConfigJson
    }
  },
  components: {
    LangSelector
  },
  props: {
  }
}
</script>

<style scoped lang="scss">

footer {
    margin-right: auto;
    margin-left: auto;
    margin-top: 24px;
    background-color: var(--main-active-color);

}

.footer {}

footer .footer_navigate {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

footer .footer_navigate>div {
    margin: 12px;
    padding: 6px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    flex: 1 0 15%;
}

footer .footer_navigate>div.site_info {
    flex: 1 0 40% !important;
    color: #A9ABAC;
}

footer .footer_navigate>div.site_info p {
    font-weight: 400;
    //font-size: 8px;
    //line-height: 10.5px;
    font-size: 12px;
    line-height: 16.5px;
}

footer .footer_navigate>div.site_index {
    flex: 1 0 30% !important;
}

footer .footer_navigate>div h4 {
    font-weight: 500;

}

footer .footer_navigate>div>img {
    max-width: 24em;
    cursor: pointer;
}

footer .footer_navigate>div ul {
    list-style: none;
    column-gap: 20px;
    column-count: 3;
}

footer .footer_navigate>div ul li {
    //white-space: nowrap;
    padding: 2px 0;
}

footer .footer_navigate>div.site_footer {
    display: none;
}

.site_info_mobile {
  display: none;
}

@media (max-width: 450px) {
  .site_index > ul {
    font-size: 10px;
  }
}
@media (max-width: 550px) {
  footer .footer_navigate > div.site_index {
    flex: 1 0 100% !important;
  }
}
@media (max-width: 750px) {
    footer .footer_navigate>div.site_footer {
        display: inherit;
    }

    footer .footer_links {
        display: none !important;
    }

    .site_info_mobile {
      display: block;
    }
}

@media (max-width: 1000px) {}

footer .footer_navigate>div.site_footer ul {
    column-count: 1;
}

footer .footer_navigate>div ul li {}

footer .footer_navigate>div a {
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
    transition: all .3s ease;
    cursor: pointer;
}

footer .footer_navigate>div a:hover {
    color: var(--main-accent-color);
}

footer .footer_links {
    margin: 16px auto 0;
    width: 100%;
    background-color: #58585B;
    min-height: 29px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #FFFFFF;
}

footer .footer_links>* {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    line-height: 22px;
    padding-left: 8px;
    padding-right: 8px;

}

footer .site_info {
    min-width: 350px;
    flex: 35% !important;
}

footer .rta18 {
    display: flex;
    flex-flow: row wrap !important;
    align-items: flex-start !important;
    justify-content: center !important;
    /* flex: 10%!important; */
    align-content: flex-start !important;
    color: #A9ABAC;
}

footer .rta18 svg {
    margin: 6px;
    margin-top: 12px;
    margin-bottom: 12px;
}

footer .rta18 .eighteen {
    padding: 12px;
    line-height: 1.5rem;
    border-radius: 50%;
    color: #A9ABAC;
    background-color: #ffffff28;
}

footer .footer_links>a {
    color: #fff;

}

footer .footer_links>a {
    transition: all .3s ease;
    cursor: pointer;
    text-decoration: underline #fff;
}

footer .footer_navigate>div a {
    margin-right: 12px;
}

footer .footer_links>a:hover {
    color: var(--main-accent-color);
    text-decoration: underline var(--main-accent-color);
}

footer .footer_links>span {}

footer .site_logo img {
    width: 200px;
}

footer p {
    font-weight: 400;
    font-size: 12px;
    line-height: 175%;
}

footer h4 {
    font-size: 20px;
    text-transform: uppercase;
}

@media (max-width: 750px) {
    footer .footer_navigate>div ul {
        column-count: 2;
    }

    footer .site_logo {
        flex-flow: row !important;
        justify-content: space-around !important;
    }
}

</style>
