export default {
  en: {
    name: 'English',
    iso: 'gb',
    load: () => { return import('./en.json') }
  },
  ru: {
    name: 'Русский',
    iso: 'ru',
    load: () => { return import('./ru.json') }
  },
  es: {
    name: 'Español',
    iso: 'es',
    load: () => { return import('./es.json') }
  },
  tr: {
    name: 'Türkçe',
    iso: 'tr',
    load: () => { return import('./tr.json') }
  },
  he: {
    name: 'עִברִית',
    iso: 'il',
    load: () => { return import('./he.json') }
  },
  pl: {
    name: 'Polski',
    iso: 'pl',
    load: () => { return import('./pl.json') }
  },
  de: {
    name: 'Deutsch',
    iso: 'de',
    load: () => { return import('./de.json') }
  },
  ar: {
    name: 'عربي',
    iso: 'ps',
    load: () => { return import('./ar.json') }
  },
  fi: {
    name: 'Suomalainen',
    iso: 'fi',
    load: () => { return import('./fi.json') }
  },
  ro: {
    name: 'Română',
    iso: 'ro',
    load: () => { return import('./ro.json') }
  },
  fr: {
    name: 'Français',
    iso: 'fr',
    load: () => { return import('./fr.json') }
  },
  it: {
    name: 'Italiano',
    iso: 'it',
    load: () => { return import('./it.json') }
  },
  hi: {
    name: 'हिंदी',
    iso: 'in',
    load: () => { return import('./hi.json') }
  },
  sv: {
    name: 'Svenska',
    iso: 'se',
    load: () => { return import('./sv.json') }
  },
  no: {
    name: 'Norsk',
    iso: 'no',
    load: () => { return import('./no.json') }
  },
  ja: {
    name: '日本',
    iso: 'jp',
    load: () => { return import('./ja.json') }
  },
  el: {
    name: 'Ελληνικά',
    iso: 'gr',
    load: () => { return import('./el.json') }
  },
  ko: {
    name: '한국인',
    iso: 'kr',
    load: () => { return import('./ko.json') }
  },
  da: {
    name: 'Dansk',
    iso: 'dk',
    load: () => { return import('./da.json') }
  },
  cs: {
    name: 'Čeština',
    iso: 'cz',
    load: () => { return import('./cs.json') }
  },
  hr: {
    name: 'Hrvatski',
    iso: 'hr',
    load: () => { return import('./hr.json') }
  },
  hu: {
    name: 'Magyar',
    iso: 'hu',
    load: () => { return import('./hu.json') }
  },
  uk: {
    name: 'Українська',
    iso: 'ua',
    load: () => { return import('./uk.json') }
  },
  pt: {
    name: 'Português',
    iso: 'pt',
    load: () => { return import('./pt.json') }
  },
  nl: {
    name: 'Nederlands',
    iso: 'nl',
    load: () => { return import('./nl.json') }
  },
  zh: {
    name: '中國人',
    iso: 'cn',
    load: () => { return import('./zh.json') }
  }
}
