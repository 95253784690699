import Vue from 'vue'
import router from '@/router'
import App from '@/App.vue'
import store from '@/helpers/store.js'
import { i18n } from 'vue-lang-router'

Vue.config.productionTip = false
Vue.config.disableNoTranslationWarning = true

export default new Vue({
  router,
  i18n,
  render: h => h(App),
  store
}).$mount('#app')
