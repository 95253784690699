<template>
  <header>
    <div class="header_wrapper" id="headerWrapper">
        <div class="header_slim">
            <div title="Menu" class="slim_menu_btn" v-if="!this.$store.state.burgerHidden">
                <input id="burger_slim" type="checkbox"/>
                <label for="burger_slim" class="burger" v-on:click="toggleMenu"></label>
            </div>
            <div class="slim_logo">
                <a v-bind:title="ConfigJson.WebsiteName" class="header_logo" href="/">
                    <img v-bind:alt="ConfigJson.WebsiteName" v-bind:src="ConfigJson.LogoImage" width="196" height="62"/>
                </a>
            </div>
            <LangSelector v-bind:locale="this.$i18n.locale"/>
            <div class="Slim_search">
                <div class="header_search">
                    <input v-model="SearchInput" type="text" v-bind:placeholder="$t('searchPlaceholder')" id="searchSmall" @keyup.enter="EnterOnSearchInput" />
                    <a title="Search" v-bind:href="CleanedUrl"><svg xmlns="http://www.w3.org/2000/svg" width="16px" viewBox="0 -20 512 532">
                            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z" />
                        </svg></a>
                </div>
            </div>
        </div>
        <div class="header_primary">
            <div title="Menu" class="slim_menu_btn" v-if="!this.$store.state.burgerHidden">
                <input id="burger" type="checkbox"/>
                <label for="burger" class="burger" v-on:click="toggleMenu"></label>
            </div>
            <a v-bind:title="ConfigJson.WebsiteName" class="header_logo" href="/">
                <img v-bind:alt="ConfigJson.WebsiteName" v-bind:src="ConfigJson.LogoImage" width="256" height="81"/>
                <span class="OnlineCounter"> {{ this.$store.state.online }} </span>
            </a>
            <div class="header_search">
                <input v-model="SearchInput" type="text" v-bind:placeholder="$t('searchPlaceholder')" id="searchMain" @keyup.enter="EnterOnSearchInput" />
                <a title="Search" v-bind:href="CleanedUrl">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16px" viewBox="0 -20 512 532">
                        <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z" />
                    </svg>
                </a>
            </div>
            <LangSelector v-bind:locale="this.$i18n.locale"/>
            <nav class="headerSecondFloor">
                <div class="main_nav genders">
                    <ul>
                        <li>
                            <a v-bind:class=" this.$router.currentRoute.path.indexOf('/female') !== -1 ? 'active' : '' " v-bind:href="toLocale('/search/female')">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M304 176c0 61.9-50.1 112-112 112s-112-50.1-112-112s50.1-112 112-112s112 50.1 112 112zM224 349.1c81.9-15 144-86.8 144-173.1C368 78.8 289.2 0 192 0S16 78.8 16 176c0 86.3 62.1 158.1 144 173.1V384H128c-17.7 0-32 14.3-32 32s14.3 32 32 32h32v32c0 17.7 14.3 32 32 32s32-14.3 32-32V448h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H224V349.1z"/></svg>
                            {{$t('genderF')}}
                            </a>
                        </li>
                        <li>
                            <a v-bind:class=" this.$router.currentRoute.path.indexOf('/male') !== -1 ? 'active' : '' " v-bind:href="toLocale('/search/male')">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M289.8 46.8c3.7-9 12.5-14.8 22.2-14.8H424c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-33.4-33.4L321 204.2c19.5 28.4 31 62.7 31 99.8c0 97.2-78.8 176-176 176S0 401.2 0 304s78.8-176 176-176c37 0 71.4 11.4 99.8 31l52.6-52.6L295 73c-6.9-6.9-8.9-17.2-5.2-26.2zM400 80l0 0h0v0zM176 416c61.9 0 112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112s50.1 112 112 112z"/></svg>
                                {{$t('genderM')}}
                            </a>
                        </li>
                        <li>
                            <a v-bind:class=" this.$router.currentRoute.path.indexOf('/shemale') !== -1 ? 'active' : '' " v-bind:href="toLocale('/search/shemale')">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M112 0c6.5 0 12.3 3.9 14.8 9.9s1.1 12.9-3.5 17.4l-31 31L112 78.1l7-7c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-7 7 15.2 15.2C187.7 107.6 220.5 96 256 96s68.3 11.6 94.9 31.2l68.8-68.8-31-31c-4.6-4.6-5.9-11.5-3.5-17.4s8.3-9.9 14.8-9.9h96c8.8 0 16 7.2 16 16v96c0 6.5-3.9 12.3-9.9 14.8s-12.9 1.1-17.4-3.5l-31-31-68.8 68.8C404.4 187.7 416 220.5 416 256c0 80.2-59 146.6-136 158.2V432h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v8c0 13.3-10.7 24-24 24s-24-10.7-24-24v-8H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h16V414.2C155 402.6 96 336.2 96 256c0-35.5 11.6-68.3 31.2-94.9L112 145.9l-7 7c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l7-7L58.3 92.3l-31 31c-4.6 4.6-11.5 5.9-17.4 3.5S0 118.5 0 112V16C0 7.2 7.2 0 16 0h96zM352 256c0-53-43-96-96-96s-96 43-96 96s43 96 96 96s96-43 96-96z"/></svg>
                                {{$t('genderS')}}
                            </a>
                        </li>
                        <li>
                            <a v-bind:class=" this.$router.currentRoute.path.indexOf('/couple') !== -1 ? 'active' : '' " v-bind:href="toLocale('/search/couple')">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M176 288c61.9 0 112-50.1 112-112s-50.1-112-112-112S64 114.1 64 176s50.1 112 112 112zM352 176c0 86.3-62.1 158.1-144 173.1V384h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H208v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V448H112c-17.7 0-32-14.3-32-32s14.3-32 32-32h32V349.1C62.1 334.1 0 262.3 0 176C0 78.8 78.8 0 176 0s176 78.8 176 176zM271.9 360.6c19.3-10.1 36.9-23.1 52.1-38.4c20 18.5 46.7 29.8 76.1 29.8c61.9 0 112-50.1 112-112s-50.1-112-112-112c-7.2 0-14.3 .7-21.1 2c-4.9-21.5-13-41.7-24-60.2C369.3 66 384.4 64 400 64c37 0 71.4 11.4 99.8 31l20.6-20.6L487 41c-6.9-6.9-8.9-17.2-5.2-26.2S494.3 0 504 0H616c13.3 0 24 10.7 24 24V136c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-33.4-33.4L545 140.2c19.5 28.4 31 62.7 31 99.8c0 97.2-78.8 176-176 176c-50.5 0-96-21.3-128.1-55.4z"/></svg>
                                {{$t('genderC')}}
                            </a>
                        </li>
                    </ul>
                </div>
                <nav class="main_nav">
                    <ul class="clearfix">
                        <li v-for="(NavItem, index) in ConfigJson.Navigation" :key="index" v-bind:class=" NavItem.HasSubmenu == true ? 'have_submenu' : '' " v-bind:title="NavItem.Tooltip">
                            <localized-link v-bind:to="NavItem.Link != undefined ? NavItem.Link : '#'" v-bind:class="NavItem.Link == '/random' ? 'roulette' : ''">
                              {{ $t(NavItem.Title) }}
                            </localized-link>
                            <div v-if="NavItem.HasSubmenu == true" class="nav_submenu">
                                <ul class="clearfix">
                                    <li v-for="(SubItem, index) in NavItem.SubMenu" :key="index">
                                        <a v-bind:href="toLocale(SubItem.Url)" v-bind:class="SubItem.Important == true ? 'important' : '' ">
                                            <img v-if="SubItem.Icon != ''" class="" v-bind:alt="SubItem.Icon" width="20" height="13" v-bind:src="'/assets/flags/h24/'+SubItem.Icon+'.jpg'" />
                                            {{ SubItem.Important ? $t(SubItem.Title) : $t(`${NavItem.SubPrefix}.${SubItem.Title}`) }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </nav>
            </nav>
        </div>
        <div class="Slide_Menu OnlyMobile" v-bind:class=" SlideMenuToggle ? 'Active' : '' ">
            <template v-for="(NavItem, index) in ConfigJson.Navigation">
<!--                <Accordion :key="index" v-if="NavItem.Link !== '/random'" simple="true" v-bind:title="$t(NavItem.Title)" v-bind:Link="NavItem.Link" v-bind:HasSubmenu="NavItem.HasSubmenu">-->
<!--                    <ul class="SlideLinks clearfix" v-if="NavItem.HasSubmenu == true">-->
<!--                        <li v-for="(SubItem, index) in NavItem.SubMenu" :key="index">-->
<!--                            <localized-link v-bind:to="SubItem.Url">-->
<!--                                <img v-if="SubItem.Icon != ''" class="Menu_Country_Flag" v-bind:alt="SubItem.Icon" width="20" height="13" v-bind:src="'/assets/flags/h24/'+SubItem.Icon+'.jpg'" />-->
<!--                                {{ $t(SubItem.Title) }}-->
<!--                            </localized-link>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </Accordion>-->
                <localized-link :key="index" v-if="NavItem.Link == '/random'" v-bind:to="NavItem.Link" class="side_roulette roulette"> {{ $t(NavItem.Title) }} </localized-link>
            </template>
        </div>
    </div>
  </header>
</template>

<script>

// import Vue from 'vue'

import ConfigJson from '@/assets/Config.json'
import LangSelector from '@/components/LangSelector.vue'
import Vue from 'vue'

export default {
  name: 'HeaderPrimary',
  data () {
    return {
      SearchInput: '',
      SlideMenuToggle: true,
      ConfigJson: ConfigJson
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      Vue.config.closeMenu = () => {
        const body = document.body
        if (!this.SlideMenuToggle && body.querySelectorAll('[class="burger"]').length > 0) {
          // костыль, но я хз как повлиять на css по другому
          body.querySelectorAll('[class="burger"]')[0].click()
        }

        body.classList.remove('Overflow')
        this.SlideMenuToggle = false
      }
    })
  },
  components: {
    LangSelector
  },
  props: {
  },
  computed: {
    CleanedUrl: function () {
      let si = this.SearchInput
      if (this.SearchInput.length !== 0) {
        si += '/'
      } else {
        return '/search/'
      }

      const path = String('/search/' + this.$route.params.QueryHash + '/' + si)
        .replace('undefined', '')
        .replace('//', '/')
        .split('/')

      return '/search/' + path[path.length - 2] + '/result'
    }
  },
  methods: {
    toLocale (url) {
      return this.$i18n.locale === 'en' ? url : `/${this.$i18n.locale}${url}`
    },
    toggleMenu () {
      this.SlideMenuToggle = !this.SlideMenuToggle
      const body = document.body
      if (!body.classList.contains('Overflow')) {
        body.classList.add('Overflow')
      } else {
        body.classList.remove('Overflow')
      }
    },
    EnterOnSearchInput () {
      window.location.href = this.CleanedUrl
    },
    getFlagEmoji (countryCode) {
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt())
      return String.fromCodePoint(...codePoints)
    }
  }
}

</script>

<style scoped lang="scss">

$accentColor: '%23FF5C00';

.static_page .slim_menu_btn {
  visibility: hidden;
}

div.static_links {
    position: absolute;
    right: 0;
    left: 0;
    top: 0px;
    background-color: #2E3943;
    height: 29px;
}

div.static_links ul {
    display: flex;
    list-style: none;
    justify-content: space-around;
    justify-content: center;
    height: 100%;
    align-items: center;
}

div.static_links ul li {
    cursor: pointer;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline #fff;
    position: relative;
}

div.static_links ul li * {
    cursor: pointer;
    padding: 9px 42px;
    transition: all .3s ease;
}

div.static_links ul li a:hover {
    color: var(--main-accent-color);
    text-decoration: underline var(--main-accent-color);
}

div.static_links a {
    width: 100%;
    display: block;
    color: #fff;
}

div.header_primary {
    margin-right: auto;
    margin-left: auto;
    padding: 12px 100px 6px;
    margin: 29px auto 0px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    transition: all .3s ease;
    max-width: 1920px;

}

div.header_slim {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--main-active-color);
    display: none;
    z-index: 42;
}

div.header_slim .slim_menu_btn,
div.header_primary .slim_menu_btn {
    border-radius: 50%;
    /* border: 2px solid #ffffff32; */
    height: 32px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--main-active-color);
}

div.header_slim .slim_menu_btn *,
div.header_primary .slim_menu_btn * {
    cursor: pointer;
}

div.header_slim .slim_menu_btn,
div.header_primary .slim_menu_btn {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.header_slim img {
    max-height: 100px;

}

div.header_slim .slim_menu_btn svg,
div.header_primary .slim_menu_btn svg {
    width: 64%;
    height: 64%;
}

div.header_slim .slim_menu_btn,
div.header_primary .slim_menu_btn {
    width: 24px;
    height: 28px;
    margin: 6px 6px;
}

div.header_slim svg {
    fill: var(--main-accent-color);
}

a.header_logo>img {
    width: 256px;
    max-width: 24em;
    transition: all .3s ease;
    aspect-ratio: 35/10;
}

a.header_logo * {
    cursor: pointer;
}

.headerSecondFloor {
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.main_nav {
    text-align: center;
    display: inline-block;
    padding: 0;
}

.main_nav>ul,
nav.main_nav>ul>div {
    display: inline-block;
    text-align: left;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    transition: all .3s ease;
    height: 32px;
}

.main_nav>ul>li,
nav.main_nav>ul>div>li {
    display: block;
    color: #fff;
    text-decoration: none;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    transition: color .3s ease;
    display: inline;
    position: relative;
    margin-right: 32px;
}

.main_nav>ul>li:last-child,
nav.main_nav>ul>div>li:last-child {
    margin-right: 0;
}

.main_nav>ul>li a,
nav.main_nav>ul>div>li a {
    cursor: pointer;
    line-height: 42px;
    display: flex;
    transition: all 0.3s ease;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
}

nav.main_nav>ul>li>a {
    /* padding: 0 28px 0 28px; */
}

div.main_nav>ul>li>a {
    padding: 0;
    /* margin: 0 16px 0 16px; */
}

.main_nav svg {
    height: 16px;
    margin: 0 6px;
    fill: #fff;
    transition: all .3s ease;
}

nav.main_nav>ul>li.have_submenu:after,
nav.main_nav>ul>div>li.have_submenu:after {
    content: "";
    top: 19px;
    width: 8px;
    height: 4px;
    right: -16px;
    background-image: url("data:image/svg+xml,%3Csvg width='8' viewBox='0 0 6 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 3L3 0.5L0.5 3' stroke='#{$accentColor}' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    position: absolute;
    transition: all .3s ease;
    transform: rotateZ(180deg);
    font-size: 1.2em;
}

nav.main_nav>ul>li.have_submenu:hover:after,
nav.main_nav>ul>div>li.have_submenu:hover:after {
    transform: rotateZ(0);
    top: 19px;
    color: var(--main-accent-color);
}

/*.main_nav>ul>li>a:before,
nav.main_nav>ul>div>li>a:before,*/
.main_nav.genders>ul>li>a:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    opacity: 0;
    background-color: var(--main-accent-color);
    transition: all .3s ease;
}

.main_nav>ul>li>a:hover:before,
nav.main_nav>ul>div>li>a:hover:before,
.main_nav>ul>li>a.active:before {
    opacity: 1;
}

.main_nav>ul>li>a:hover,
nav.main_nav>ul>div>li>a:hover {
    color: var(--main-accent-color);
}

.main_nav>ul>li>a:hover svg {
    fill: var(--main-accent-color);
}

nav.main_nav>ul li.have_submenu div.nav_submenu {
    background-color: var(--main-active-color);
    border: 0.5px solid #2E3943;
    position: fixed;
    padding: 6px;
    margin: 0 auto;
    left: 5%;
    right: 5%;
    z-index: -999;
    visibility: hidden;
    opacity: 0;
    border-radius: 4px;
    transition: transform .4s ease, opacity .2s ease;
    transform-style: preserve-3d;
    transform: rotateX(-75deg);
    transform-origin: 0 0;
}

nav.main_nav>ul li.have_submenu:hover div.nav_submenu {
    visibility: visible;
    opacity: 1;
    z-index: 999;
    transform-style: preserve-3d;
    transform: rotateX(0deg);
}

nav.main_nav>ul li.have_submenu div.nav_submenu>ul {
    column-gap: 24px;
    column-count: 5;
    list-style: none;
    margin-left: 64px;
}

nav.main_nav>ul li.have_submenu div.nav_submenu>ul>li {

    white-space: nowrap;
    text-align: left;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 33pt;
    font-size: 12px;
}

nav.main_nav>ul li.have_submenu div.nav_submenu>ul>li a {

    cursor: pointer;
    color: #fff;

}

nav.main_nav>ul li.have_submenu div.nav_submenu>ul>li a.important {
    color: var(--main-accent-color);
}

nav.main_nav>ul li.have_submenu div.nav_submenu>ul>li:hover a.important {
    text-decoration: underline var(--main-accent-color);
}

nav.main_nav>ul li.have_submenu div.nav_submenu>ul>li img {

    cursor: pointer;
    margin-right: 8px;
}

nav.main_nav>ul li.have_submenu div.nav_submenu>ul>li:hover a {

    color: var(--main-accent-color);
}

.Slide_Menu_Overlay {
    /*transition: all .3s ease;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -500;
    opacity: 0;
    background: #00000060;*/
}

.Slide_Menu_Overlay.Active {
    opacity: 1;
}

.Slide_Menu {
    transition: all .3s ease;
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: auto;
    overflow: overlay;
    transition: height .3s ease, transform .3s ease, opacity .6s ease;
}

.Slide_Menu.Active {
    opacity: 1;
    /*max-height: 60vh;*/
    height: fit-content;
    transition: height .3s ease, transform .3s ease, opacity .6s ease;

}

.Slide_Menu>.Slide_logo {
    width: 100%;
    padding-bottom: 6px;
}

.Fixed_Close_Btn {
    position: fixed;
    height: 10%;
    width: 10%;
    right: 8px;
    top: 8px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.64);
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
}

.Fixed_Close_Btn svg {
    fill: var(--main-accent-color);
    width: 64%;
    height: 64%;
}

.Slide_Menu ul {
    list-style: none;
}

.header_wrapper {
    background-color: var(--main-active-color);
    width: 100%;
}

.slim_logo {
    margin-right: 12px;
}

nav.main_nav>ul>li:first-child {
    visibility: hidden;
    width: 0;
    opacity: 0;
    transition: all .3s ease;
    display: none;
}

@media (max-width: 1250px) {

    nav.main_nav>ul>li:nth-last-child(2) {
        visibility: hidden;
        width: 0;
        opacity: 0;
        transition: all .3s ease;
        display: none;
    }

    .header_primary .header_search input,
    .Slim_search .header_search input,
    .header_search input {
        width: 340px;
    }
}

@media (max-width: 1080px) {
    .header_primary .header_search input,
    .Slim_search .header_search input {
        max-width: 128px;
    }

}

@media (max-width: 1080px) {

    nav.main_nav {
        display: none;

    }

    div.static_links {
        display: none;

    }

    div.header_primary {
        display: none;

    }

    div.header_slim {
        display: flex;
        height: 64px;

    }

}

@media (max-width: 750px) {

    a.header_logo img {
        max-width: 14em;
    }

    div.header_slim[data-v-611fdc35] {
        padding-top: 8px;
    }
}

@media (max-width: 512px) {

    .Slim_search {
        width: 90%;
    }

    .header_wrapper {
        min-height: 100px;
    }

    .Slide_Menu.Active {
        margin-top: 100px;
    }

    /* .Slim_search > .header_search a{
border-radius: 32px;
    } */

    .header_slim {
        display: flex;
        justify-content: center !important;
        flex-wrap: wrap;
        position: fixed;
        z-index: 64;
        top: 0;
        left: 0;
        right: 0;
        height: 120px !important;
    }

    .Slim_search>.header_search {
        width: 100%;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .header_primary .header_search input,
    .Slim_search .header_search input {
        max-width: 90vw;
        width: 100%;
    }

}

$burger-height: 28px;
$burger-width: 42px;
$burger-item: 4px;
$burger-item-offset: $burger-height - $burger-item * 2;
$burger-item-shadow: -1 * $burger-item - ($burger-item-offset - $burger-item) / 2;

#burger, #burger_slim {
    display: none;

    &:checked+.burger:before {
        transform: translateY($burger-height / 2 - $burger-item * 0.5) rotateZ(45deg);
    }

    &:checked+.burger:after {
        filter: initial;
        transform: translateY($burger-height / 2 - $burger-item * 1.5) rotateZ(-45deg);
    }
}

.burger {
    border-radius: $burger-item / 2;
    color: var(--main-accent-color);
    cursor: pointer;
    height: $burger-height;
    margin: auto; // Centering
    user-select: none;
    width: $burger-width;

    &:before,
    &:after {
        border-radius: inherit;
        background: currentColor;
        content: "";
        display: block;
        height: $burger-item;
        transition: 0.5s;
    }

    &:after {
        filter: drop-shadow(0 $burger-item-shadow currentColor);
        transform: translateY($burger-item-offset);
    }
}

</style>
