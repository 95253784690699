import Vue from 'vue'
import LangRouter from 'vue-lang-router'

import ConfigJson from '@/assets/Config.json'
import translations from '../lang/translations'
import localizedURLs from '../lang/localized-urls'

const AboutView = () => import('@/views/static/AboutView.vue')
const BlogView = () => import('@/views/BlogView.vue')
const CopyrightView = () => import('@/views/static/CopyrightView.vue')
const ContactView = () => import('@/views/static/ContactView.vue')
const _2257View = () => import('@/views/static/2257View.vue')
const TermsView = () => import('@/views/static/TermsView.vue')
const PrivacyView = () => import('@/views/static/PrivacyView.vue')
const SearchResultView = () => import('@/views/SearchResultView')
const DmcaView = () => import('@/views/static/DmcaView')
const CookiesView = () => import('@/views/static/CookiesView')
const MapView = () => import('@/views/MapView.vue')
const PageNotFoundView = () => import('@/views/static/PageNotFoundView.vue')
const RouletteView = () => import('@/views/RouletteView.vue')
const CamView = () => import('@/views/CamView.vue')
const SearchView = () => import('@/views/SearchView.vue')
const CategoriesView = () => import('@/views/CategoriesView.vue')

Vue.use(LangRouter, {
  defaultLanguage: 'en',
  translations,
  localizedURLs,
  i18nOptions: {
    silentTranslationWarn: true
  }
})

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: HomeView
    component: SearchView
  },
  {
    path: '/categories',
    name: 'Categories',
    component: CategoriesView
  },
  {
    path: '/map',
    name: 'World Map of sex cams',
    component: MapView
  },
  {
    path: '/random/:RouletteCam*',
    name: 'Random Cam',
    component: RouletteView,
    props: true
  },
  {
    path: '/search/:QueryHash*/result',
    name: 'Search results',
    component: SearchResultView,
    props: true
  },
  {
    path: '/search/:QueryHash*',
    alias: ['/search/', '/search', '/Search/', '/Search', '/search//', '/Search/:Category?/:QueryHash*'],
    name: 'Search',
    component: SearchView,
    props: true
  },
  {
    path: '/about',
    name: 'About Us',
    component: AboutView
  },
  {
    path: '/copyright',
    name: 'Copyright',
    component: CopyrightView
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: ContactView
  },
  {
    path: '/2257',
    name: 'COMPLIANCE NOTICE',
    component: _2257View
  },
  {
    path: '/terms',
    name: 'TERMS & CONDITIONS',
    component: TermsView
  },
  {
    path: '/privacy',
    name: 'PRIVACY POLICY',
    component: PrivacyView
  },
  {
    path: '/dmca',
    name: 'DMCA',
    component: DmcaView
  },
  {
    path: '/cookies',
    name: 'Cookies Policy',
    component: CookiesView
  },
  {
    path: '/blog/:BlogPageName*',
    alias: ['/blog', '/blog/', '/Blog', '/Blog/', '/Blog/:BlogPageName*'],
    name: 'Blog',
    component: BlogView,
    props: true
  },
  {
    path: '/:Lang(' + Object.keys(translations).join('|') + ')?/:Provider/:Cam*',
    name: 'Live Cam',
    component: CamView,
    props: true
  },
  {
    path: '/*/',
    alias: ['/404/'],
    name: 'Page Not Found',
    component: PageNotFoundView
  }
]

const router = new LangRouter({
  root: '/',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.name + ' – ' + ConfigJson.WebsiteName

  const enRegexp = /^\/en\//
  if (to.path.match(enRegexp)) {
    return next({
      path: to.path.replace(enRegexp, '')
    })
  }

  next()
})

router.afterEach(function () {
  document.querySelector('link[rel="canonical"]').href = location.href
  document.querySelector('meta[property="og:url"]').content = location.href

  Object.keys(translations).forEach(function (lang, i) {
    const re = /^\/[a-z]{2}\//
    const pathname = location.pathname.replace(re, '/')

    if (lang === 'en') {
      document.querySelector('link[hreflang="en"]').href = location.origin + pathname
    } else {
      document.querySelector(`link[hreflang="${lang}"]`).href = location.origin + '/' + lang + pathname
    }
  })
})

export default router
